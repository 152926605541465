import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";
import Textarea from "../../Components/Textarea/Textarea";
import Dropdown from "../../Components/Dropdown/Dropdown";

// pop up library
import Swal from "sweetalert2";

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./Main.scss";

//Random ID
import { v4 as uuid } from "uuid";

export default function ({ title, cua, asesorData }) {
  //importamos Stein para la base de datos
  const RegistrosAPI = new SteinStore(process.env.REACT_APP_REGISTROS_API);

  //campos de la nueva cita
  const fecha = new Date();
  const [place, setPlace] = useState(null);
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [comments, setComments] = useState("");

  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false); // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false); // 2

  //Dropdown options
  const PlaceOptions = [
    { key: 0, name: "Físico" },
    { key: 1, name: "Zoom" },
  ];
  // const FocusOptions = [
  //   { key: 0, name: "Vida" },
  //   { key: 1, name: "GMM" },
  //   { key: 2, name: "Auto" },
  //   { key: 3, name: "Hogar" },
  //   { key: 4, name: "Mascota" },
  // ];
  // const ProductOptions = [
  //   { key: 1, name: "Dotal menores" }, // 
  //   { key: 2, name: "Trasciende menores" }, // 
  //   { key: 3, name: "Hombre clave protección" }, // 
  //   { key: 4, name: "Hombre clave ahorro" }, // 
  //   { key: 5, name: "GMM Versátil" }, // GMM
  //   { key: 6, name: "GMM Premium" }, // GMM
  //   { key: 7, name: "GMM Platino" }, // GMM
  //   { key: 8, name: "GMM Personaliza" }, // GMM
  //   { key: 9, name: "GMM Conexión" }, // GMM
  //   { key: 10, name: "GMM Flexibles - Ámbar" }, // GMM
  //   { key: 11, name: "GMM Flexibles - Cuarzo" }, // GMM
  //   { key: 12, name: "GMM Flexibles - Índigo" }, // GMM
  // ];
  // funciones
  function fechaString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: "2-digit", month: "2-digit", year: "numeric" };
    const fechaFormateada = fechaObj.toLocaleDateString("es-ES", opciones);
    return fechaFormateada;
  }
  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true);
  };

  const resetForm = () => {
    setPlace(null)
    setClientName("")
    setClientPhone("")
    setComments("")
    setEnviarFormulario(false)
    setEnviandoFormulario(false)
  }

  //useEffects
  useEffect(() => {
    if (
      place &&
      clientName &&
      clientPhone
    ) {
      setEnviarFormulario(true);
    } else {
      setEnviarFormulario(false);
    }
  }, [
    fecha,
    place,
    clientName,
    clientPhone,
    comments,
  ]);

  useEffect(() => {
    if (enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: "Enviando registro...",
        text: "Por favor espere un momento",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const RegistroDeCita = {
        uuid: `${uuid()}`,
        cua: asesorData?.asesorCua,
        uuid_asesor: asesorData?.uuid,
        nombre_asesor: asesorData?.asesorName,
        telefono_asesor: asesorData?.asesorPhone,
        correo_asesor: asesorData?.asesorEmail,

        estatus: "Prospecto", //Estatus por defecto de un nuevo registro
        fecha: fechaString(fecha),
        
        lugar: place ? place.name : "",
        nombre_cliente: clientName,
        telefono_cliente: clientPhone,
        
        comentarios: comments,
      };

      RegistrosAPI.append("Registros", [RegistroDeCita], {
        authentication: {
          username: process.env.REACT_APP_REGISTROS_USERNAME,
          password: process.env.REACT_APP_REGISTROS_PASSWORD,
        },
      })
        .then((res) => {
          loadingSwal.close();
          Swal.fire({
            title: "Registro enviado con éxito",
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: "Enviar otro",
            // confirmButtonColor: "",
            showCloseButton: false,
            iconColor: "#0F70D4",
            didClose: () => {
              resetForm()
            }
            //borra el botón de ver registros cuando terminemos el panel de gerentes
            
          });
        })
        .catch((error) => {
          console.error(error);
          setEnviandoFormulario(false);
        });
    }
  }, [enviandoformulario]);

  return (
    <>
      <AppPageTemplate title={title}>
        <h2 className="h2">Lugar *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={PlaceOptions}
          optionLabel={"name"}
          value={place}
          onChange={(e) => setPlace(e.value)}
        />
        <div style={{ height: "30px" }} />

        <h2 className="h2">Nombre *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"text"}
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <div style={{ height: "30px" }} />

        <h2 className="h2">Teléfono *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"number"}
          pattern="[+]?[0-9]*"
          value={clientPhone}
          onChange={(e) =>
            setClientPhone((v) =>
              e.target.validity.valid ? e.target.value : v
            )
          }
        />
        <div style={{ height: "30px" }} />


        <h3 className="h3">Comentarios</h3>
        <div style={{ height: "10px" }} />
        <Textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </AppPageTemplate>

      {enviarformulario ? (
        <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}>
          <i className="pi pi-send"></i>
        </button>
      ) : (
        <button className="PiButton PiButtonDisabled">
          <i className="pi pi-send"></i>
        </button>
      )}
    </>
  );
}
