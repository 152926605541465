import "./Dropdown.scss"

import { Dropdown } from 'primereact/dropdown';

export default function ({
    value,
    onChange,
    options,
    optionLabel,
    showClear=false,
}) {
    return (<div className="drop">
        <Dropdown 
            value={value}
            onChange={onChange}
            options={options}
            optionLabel={optionLabel}
            showClear={showClear}
            appendTo={"self"}
        />
    </div>)
}