import "./TextArea.scss"

export default function ({
    value,
    onChange,
}) {

    function textAreaAdjust(e) {
        e.target.style.height = "1px";
        e.target.style.height = (25 + e.target.scrollHeight) + "px";
    }


    return (
        <textarea
            onKeyUp={e => textAreaAdjust(e)}
            value={value}
            onChange={onChange}
            className="TextArea">
        </textarea>)
}